//
// Card
// --------------------------------------------------

.card {
  position: relative;
  border: 0;
  border-radius: 0;
  background-color: $white;
  box-shadow: $box-shadow-card;
  // &::after {
  //   display: block;
  //   position: absolute;
  //   bottom: -10px;
  //   left: 20px;
  //   width: calc(100% - 40px);
  //   height: 35px;
  //   background-color: $white;
  //   box-shadow: 0 19px 28px 5px rgba($gray-dark, .09);
  //   content: '';
  //   z-index: -1;
  // }
  > img {
    display: block;
    width: 100%;
  }
}
a.card { text-decoration: none; }
.card-header,
.card-footer {
  border-radius: 0 !important;
  background-color: $white;
  h1, h2, h3, h4, h5, h6, p,
  .h1, .h2, .h3, .h4, .h5, .h6 { margin: 0; }
}
.card-header {
  border-bottom: 1px solid $border-color;
  .nav-tabs {
    padding-bottom: 0;
  }
}
.card-footer { border-top: 1px solid $border-color; }
.card-img-top,
.card-img-bottom { border-radius: 0 !important; }
.card-body {
  padding: $grid-vertical-step;
  padding-bottom: floor($grid-vertical-step * 1.1);
}
.card-title {
  margin: 0;
  font: {
    family: $font-family-headings;
    size: $font-size-xl;
    weight: $font-weight-headings;
  }
  > a {
    transition: color .3s;
    color: $headings-color;
    text-decoration: none;
    &:hover {
      color: $primary;
    }
  }
}
.card-text {
  padding-top: floor($grid-vertical-step / 2);
  color: $gray;
}

// Contacts Widget Inside
.card .widget-contacts {
  margin-bottom: floor($grid-vertical-step / 6);
  ul > li {
    padding: {
      top: 6px !important;
      bottom: 6px !important;
    }
    border-top: 0;
    > i { top: 8px !important; }
  }
}
